<template>
    <v-container class="faturamento">
        <HeadbarPage :title="'Relatórios > Faturamento'"></HeadbarPage>
        <v-card class="mt-5">
            <v-card-text
                class="primary--text text-left font-weight-black display-1 px-3"
            >
                <v-icon large class="ma-2"> mdi-progress-close </v-icon
                >{{ title }}
            </v-card-text>
            <v-card-text class="text-left px-3">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="selectedType"
                            solo
                            :items="itemsType"
                            label="Selecione o tipo de relatório"
                            item-text="name"
                            item-value="value"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-menu
                            ref="menu"
                            solo
                            v-model="menuDate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    solo
                                    :value="formatDate(dateSelected)"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                class="picker-faturamento"
                                locale="pt-br"
                                solo
                                type="month"
                                v-model="dateSelected"
                                :active-picker.sync="activePicker"
                                @input="menuDate = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col v-show="superUsuario" >
                        <v-autocomplete
                            solo
                            small
                            label="Selecione Apresentante"
                            v-model="apresentanteSelecionado"
                            :items="apresentantes"
                            :filter="customFilter"
                            item-value="cd_apresentante"
                            item-text="cd_apresentante"
                            spellcheck="false"
                            :loading="loading"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span :class="{'text-gray': !data.item.st_ativo}">{{ data.item.nome_exibicao || data.item.nm_apresentante }}</span><br/>
                                        <div>
                                            <small>Código apresentante: {{data.item.cd_apresentante}}</small>
                                            
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col >
                        <v-btn
                            @click="gerarRelatorio(true)"
                            color="primary"
                            large
                            :disabled="!selectedType || !dateSelected || !apresentanteSelecionado"
                        >
                            Gerar Relatório
                        </v-btn>
                        
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="my-5">
            <v-data-table
                :headers="headers"
                :loading="loadingTable"
                :options.sync="options"
                :items="itens"
                :server-items-length="totalTitulos"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, 100],
                    itemsPerPageText: 'Itens por página:'
                }"
            >
                <template v-slot:[`item.dt_cadastro`]="{ item }">
                    {{ formatDataVencimento(item.dt_cadastro) }}
                </template>
                <template v-slot:[`item.dt_vencimento`]="{ item }">
                    {{ formatDataVencimento(item.dt_vencimento) }}
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span>
                        {{formatDocumento(item)}}
                    </span>
                </template>
                <template v-slot:[`item.dt_historico`]="{ item }">
                    {{formatDataVencimento(item.dt_historico)}}
                </template>
                <template v-slot:[`item.dt_atualizacao`]="{ item }">
                    {{formatDataVencimento(item.dt_atualizacao)}}
                </template>
            </v-data-table>
            <v-row >
                <v-col class="d-flex justify-end ma-5">
                    <v-btn
                        @click="modalAvisoConfirmacao = true"
                        color="primary"
                        large
                        :disabled="!selectedType || !dateSelected || !apresentanteSelecionado"
                    >
                        Exportar por e-mail
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            v-if="modalAvisoConfirmacao"
            v-model="modalAvisoConfirmacao"
            max-width="600px"
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="headline primary--text">
                    <v-icon class="mr-5">mdi-alert-circle</v-icon> Relatorio de Faturamento
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <strong>Atenção!</strong> Este relatorio sera enviado no e-mail: {{userEmail}}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="modalAvisoConfirmacao = false"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="enviarRelatorio"
                    >
                        Enviar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </v-container>
</template>

<script>
import Vue from 'vue';
import ApresentanteService from '@/services/apresentanteService';
import * as Formatter from '@/helpers/Formatter';
import moment from 'moment';

export default {
    components: {
        HeadbarPage: () => import('@/components/atoms/HeadbarPage')
    },
    data() {
        return {
            modalAvisoConfirmacao: false,
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            headers:[],
            loadingTable: false,
            itens: [],
            options: {
                page: 1,
                itemsPerPage: 20
            },
            totalTitulos: 0,
            title: 'Relatório de Faturamento',
            selectedType: null,
            itemsType: [
                { name: 'Comandos', value: 'comandos' },
                { name: 'Envios', value: 'envios' },
                { name: 'Envios reembolso', value: 'envios-reembolso' },
            ],
            menuDate: false,
            activePicker: null,
            dateSelected: new Date().toISOString().substr(0, 7),
            apresentanteSelecionado: null,
            apresentantes: [],
            loading: false,
            headersComandos:[
                {text: 'Dt. Cadastro', value: 'dt_cadastro'},
                {text: 'Id título', value: 'id_titulo'},
                {text: 'Título', value: 'ds_nosso_numero'},
                {text: 'CPF/CNPJ', value: 'nu_identificacao_devedor'},
                {text: 'Devedor', value: 'nm_devedor'},
                {text: 'Dt. Vencimento', value: 'dt_vencimento'},
                {text: 'Valor R$', value: 'valor'},
                {text: 'Dt. Envio comando', value: 'dt_atualizacao'},
                {text: 'Comando Enviado', value: 'ds_status'},
                {text: 'Periodo Plataforma', value: 'periodo_plataforma'},
                {text: 'Atraso pré cadastro', value: 'periodo_plataforma'},
                {text: 'Carteira', value: 'cd_carteira'}
            ],
            headersEnvios:[
                {text: 'Dt. Cadastro', value: 'dt_cadastro'},
                {text: 'Id título', value: 'id_titulo'},
                {text: 'Título', value: 'ds_nosso_numero'},
                {text: 'CPF/CNPJ', value: 'nu_identificacao_devedor'},
                {text: 'Devedor', value: 'nm_devedor'},
                {text: 'Valor R$', value: 'valor'},
                {text: 'Carteira', value: 'cd_carteira'},
                {text: 'Atraso pré cadastro', value: 'periodo_plataforma'},
                {text: 'Data última atual', value: 'dt_historico'},
                {text: 'Status', value: 'cd_status'},
            ],
            superUsuario: false

        };
    },
    mounted() {

        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }

        this.listarApresentantes();
        this.getApresentanteAtual();
    },
    computed: {
        userEmail() {
            return this.$store.state.user.email;
        },
    },
    methods: {
        customFilter(item, queryText) {
            const searchText = queryText.toLowerCase();
            const textOne = item.nome_exibicao?.toLowerCase() || '';
            const textTwo = item.nm_apresentante.toLowerCase();
            const textThree = item.cd_apresentante.toLowerCase();
            return textOne.includes(searchText) || textTwo.includes(searchText) || textThree.includes(searchText);
        },
        enviarRelatorio(){
            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            let payload = {
                date: this.dateSelected,
                cd_apresentante: this.apresentanteSelecionado
            };
            apresentanteService.envioRelatorioFaturamentoEmail(payload).then(
                () => {
                    this.modalAvisoConfirmacao = false;
                    this.snackbar = true;
                    this.colorSnackbar = 'success';
                    this.mensagemSnackbar = 'Relatório enviado com sucesso!';   

                },
                error => {
                    this.snackbar = true;
                    this.colorSnackbar = 'error';
                    this.mensagemSnackbar = 'Erro ao enviar relatório!';
                    console.log(error);
                }
            );

        },
        formatDocumento(titulo) {
            let tp_identificacao_devedor = titulo.nu_identificacao_devedor.length > 11 ? "1" : "2";
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                tp_identificacao_devedor
            );
        },
        formatDataVencimento(item) {
            return moment(item).format('DD/MM/YYYY');
        },
        formatDateForSend(date) {
            if (!date) return null;
            const [year, month] = date.split('/');
            return `${year}-${month}`;
        },
        getApresentanteAtual() {
            this.apresentanteSelecionado = this.$store.state.user.cd_apresentante;
        },
        gerarRelatorio(firstTime = false) {
            if (!this.apresentanteSelecionado || !this.selectedType) {
                return false
            }

            this.loadingTable = true;
            this.headers = [];
            this.itens = [];
            this.totalTitulos = 0
            if (firstTime)
                this.options.page = 1;

            let apresentanteService = new ApresentanteService(
                Vue.http,
                this.$store
            );
            let payload = {
                type: this.selectedType,
                date: this.dateSelected,
                cd_apresentante: this.apresentanteSelecionado,
                page: this.options.page,
                per_page: this.options.itemsPerPage,
            };

            apresentanteService.getFaturamentoApresentante(payload).then(
                response => {
                    console.log(response);
                    if(this.selectedType === 'comandos'){
                        this.headers = this.headersComandos;
                        this.itens = response.results;
                    }else{
                        this.headers = this.headersEnvios;
                        this.itens = response.results;
                    }
                    this.totalTitulos = response.total_results
                    console.log(this.itens, this.headers);
                    this.loadingTable = false;
                },
                error => {
                    console.log(error);
                    this.loadingTable = false;
                }
            );
            console.log(this.selectedType, this.dateSelected, this.apresentanteSelecionado);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month] = date.split('-');
            return `${month}/${year}`;
        },
        listarApresentantes() {
            this.loading = true;
            return new Promise((resolve, reject) => {
                try {
                    let apresentanteService = new ApresentanteService(
                        Vue.http,
                        this.$store
                    );
                    apresentanteService.listarApresentantes(this.filterSelected).then(
                        response => {
                            if (response.status == 200) {
                                let arr_apresentantes = [...response.body];
                                this.apresentantesOrderByCd = response.body;

                                arr_apresentantes.sort((a, b) => {
                                    let nomeA = a.nm_apresentante.toUpperCase(); // ignorar maiúsculas e minúsculas
                                    let nomeB = b.nm_apresentante.toUpperCase(); // ignorar maiúsculas e minúsculas
                                    if (nomeA < nomeB) {
                                        return -1;
                                    }
                                    if (nomeA > nomeB) {
                                        return 1;
                                    }
                                    return 0; // nomes iguais
                                });
                                this.apresentantes = arr_apresentantes;
                                this.loading = false;
                            }
                        },
                        error => {
                            console.log(error);
                            this.loading = false;
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
    },
    watch: {
        options: {
            handler() {
                this.gerarRelatorio();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.picker-faturamento{
    .v-btn--active {
        color: blue !important;
    }
}
/* Add your component styles here */
</style>