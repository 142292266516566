<template>
    <div class="ma-2 pa-2">
        <v-card height="90vh" class="pa-3">
            <v-tabs class="grey lighten-3">
                <v-tab class="grey lighten-3">
                    <v-icon left> mdi-cog </v-icon>
                    Configurações
                </v-tab>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text class="ma-3 pa-3">
                            <v-card-text>
                                Configurações do Envio de E-mails Automáticos do
                                Sistema
                            </v-card-text>
                            <v-row>
                                <v-col cols="12" md="12" lg="3">
                                    <v-card-text>
                                        <v-icon>mdi-information-outline</v-icon>
                                        Status 102 - Protestado
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="8">
                                    <v-slider
                                        class="mt-3"
                                        min="7"
                                        persistent-hint
                                        thumb-label="always"
                                        label="Dias de Intervalo:"
                                        max="100"
                                        v-model="diasLembrete102"
                                    ></v-slider>
                                </v-col>
                            </v-row>
                            <v-row v-if="superUsuario">
                                <v-col cols="12" md="12" lg="3">
                                    <v-card-text>
                                        <v-icon>mdi-information-outline</v-icon>
                                        Nome de exibição nos emails
                                        transacionais
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" md="12" lg="4">
                                    <v-text-field
                                        class="ma-3"
                                        solo
                                        :disabled="isPartner"
                                        placeholder="Nome de Exibição"
                                        hint="Digite aqui o nome de exibição que será inserido nos emails transacionais"
                                        v-model="nomeExibicaoApresentante"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="superUsuario">
                                <v-col cols="12" md="12" lg="3">
                                    <v-card-text>
                                        <v-icon>mdi-information-outline</v-icon>
                                        Link de contato
                                    </v-card-text>
                                </v-col>
                                <v-col cols="12" md="12" lg="4">
                                    <v-text-field
                                        class="ma-3"
                                        solo
                                        :disabled="isPartner"
                                        placeholder="Link de Contato"
                                        hint="Digite aqui o endereço que será inserido nos botões de contato"
                                        v-model="linkContatoApresentante"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-col cols="12" sm="12" md="12" lg="10">
                                <v-card-text class="grey--text">
                                    Selecione no campo acima o intervalo de dias
                                    em que nosso sistema fará o envio de e-mails
                                    automáticos. Por exemplo: 10 dias, Quando o
                                    titulo é protestado, o devedor receberá
                                    e-mails automáticos frequentemente a cada 10
                                    dias.
                                </v-card-text>
                                <Snackbar
                                    v-if="snackbarResponse"
                                    :mostrarSnackbar="snackbarResponse"
                                    :corSnackbar="tipoSnackbar"
                                    :mensagemSnackbar="dialogResponse"
                                    @fecharSnackbar="snackbarResponse = false"
                                />
                                <v-card-actions>
                                    <v-btn
                                        color="primary"
                                        :loading="loading"
                                        @click="salvaDadosApresentante"
                                        >Salvar<v-icon>
                                            mdi-chevron-right</v-icon
                                        >
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
import Vue from 'vue';
import SenhaService from '@/services/redefinirSenhaService';
import EmailService from '@/services/EmailService';
import { getMensagemRequest } from '@/helpers/Utilitarios';

export default {
    name: 'AjustesMensageria',
    props: ['user_profile'],
    data() {
        return {
            loading: false,
            superUsuario: null,
            isPartner: false,
            show1: false,
            logo: require('@/assets/Logo_azul_recuperi.png'),
            valid: false,
            resposta_requisicao: '',
            load_troca_senha: false,
            password: '',
            passwordConfirmation: '',
            nomeExibicaoApresentante: null,
            linkContatoApresentante: null,
            snackbar: false,
            diasLembrete102: null,
            diasSelecionados: null,
            dialogResponse: null,
            snackbarResponse: null,
            tipoSnackbar: 'success',
            diasLembrete102Computed: null,
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v => v.length >= 2 || 'A senha deve ter 2 ou mais caracteres'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Senha é requerida',
                v => v == this.password || 'As senhas devem ser iguais'
            ]
        };
    },
    mounted() {
        this.loading = true;
        if (this.$store.getters.isPartner == 1) {
            this.isPartner = true;
        }
        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
            this.verificaNomeApresentante();
        }
        this.verificaDados();
    },
    methods: {
        getMensagemRequest,
        submit() {
            return new Promise((resolve, reject) => {
                try {
                    let senhaService = new SenhaService(Vue.http, this.$store);
                    const profile = this.user_profile;
                    senhaService
                        .salvarSenha(
                            this.password,
                            profile.ds_login,
                            profile.id_user,
                            profile.cd_apresentante
                        )
                        .then(
                            response => {
                                this.resposta_requisicao = response.body;
                                this.dialogResponse =
                                    'Senha Alterada com Sucesso';
                                this.snackbarResponse = true;
                            },
                            error => {
                                console.log(error);
                                this.load_troca_senha = false;
                            }
                        );
                } catch (e) {
                    console.log(e);
                    reject();
                    this.load_troca_senha = false;
                }
            });
        },
        verificaDados() {
            let emailService = new EmailService(Vue.http, this.$store);
            emailService.buscaLembretes().then(
                response => {
                    if (response.status == 200) {
                        this.diasLembrete102 = response.body.dias_para_envio;
                    }
                    this.loading = false;
                },
                error => {
                    console.log(error);
                    this.criarIntervalo();
                    this.loading = false;
                }
            );
        },
        verificaNomeApresentante() {
            // this.nomeExibicaoApresentante = this.$store.getters.NomeExibicaoApresentante;
            // this.linkContatoApresentante = this.$store.getters.linkContatoApresentante;
            let emailService = new EmailService(Vue.http, this.$store);
            emailService.buscaNomeApresentante().then(
                response => {
                    if (response.status == 200) {
                        this.nomeExibicaoApresentante =
                            response.body.nome_exibicao;
                        this.linkContatoApresentante =
                            response.body.link_contato;
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        salvaDadosApresentante() {
            this.loading = true;
            let diasSelecionados = this.diasLembrete102;
            let nomeSelecionado = this.nomeExibicaoApresentante;
            let contato = this.linkContatoApresentante;
            let emailService = new EmailService(Vue.http, this.$store);

            if (this.superUsuario && !this.isPartner) {
                emailService
                    .salvaNomeExibicaoApresentante(nomeSelecionado, contato)
                    .then(
                        response => {
                            if (response.status == 200) {
                                this.dialogResponse =
                                    'Dados Alterados com Sucesso';
                                this.snackbarResponse = true;
                            }
                        },
                        error => {
                            if (error.status === 400) {
                                this.tipoSnackbar = 'error';
                                this.dialogResponse =
                                    'Houve um erro ao alterar Nome de Exibição e Link de Contato';
                                this.snackbarResponse = true;
                            }
                        }
                    );
            }
            emailService.salvaLembretes(diasSelecionados).then(
                response => {
                    if (response.status == 200) {
                        this.tipoSnackbar = 'success';
                        this.dialogResponse =
                            'Dias de Intervalo atualizados com sucesso';
                        this.snackbarResponse = true;
                    }
                    this.loading = false;
                },
                error => {
                    if (error.status === 400) {
                        this.tipoSnackbar = 'error';
                        this.dialogResponse =
                            'Houve um erro ao atualizar os Dias de Intervalo';
                        this.snackbarResponse = true;
                    }
                    this.loading = false;
                }
            );
        },
        criarIntervalo() {
            let diasSelecionados = '7';
            let emailService = new EmailService(Vue.http, this.$store);
            emailService.criaLembretes(diasSelecionados).then(response => {
                if (response.status == 201) {
                    this.tipoSnackbar = 'success';
                    this.dialogResponse =
                        'Um Intervalo padrão de envios foi criado automaticamente!';
                    this.snackbarResponse = true;
                }
            });
        },
        criarNomeExibicao() {
            let nomeExibicao = this.$store.getters.cdApresentante;
            let emailService = new EmailService(Vue.http, this.$store);
            emailService.criaNomeApresentante(nomeExibicao).then(response => {
                if (response.status == 200) {
                    this.dialogResponse = response.body;
                }
            });
        }
    },
    computed: {
        computedGetUserName() {
            return this.$store.getters.getUserName;
        },
        computedGetApresentante() {
            return this.$store.getters.cdApresentante;
        },
        computedGetUserEmail() {
            return this.$store.getters.getUserEmail;
        },
        computedGetSuperUser() {
            return this.$store.getters.getSuperUser;
        }
    }
};
</script>
